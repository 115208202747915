import { Decimal } from 'decimal.js';

type ObjectType = { [key: string]: any };

export function deepCopy(obj: any) {
  const results =
    typeof obj === 'object' ? (Array.isArray(obj) ? [] : obj === null ? null : {}) : obj;
  if (typeof results === 'object' && results !== null) {
    for (const item in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj && obj.hasOwnProperty(item)) {
        results[item] = deepCopy(obj[item]);
      }
    }
  }
  return results;
}

function compare(key: string, type: 'up' | 'down') {
  return function sortGen(m: ObjectType, n: ObjectType) {
    const a = isNaN(Number(m[key])) ? m[key]?.toUpperCase() : Number(m[key]);
    const b = isNaN(Number(n[key])) ? n[key]?.toUpperCase() : Number(n[key]);

    if (a < b) {
      return type === 'up' ? -1 : 1;
    }
    if (a > b) {
      return type === 'up' ? 1 : -1;
    }
    return 0;
  };
}

export function sortObjectArrayByKey(list: ObjectType[], key: string, type?: 'up' | 'down') {
  if (list.length) {
    const tempList = deepCopy(list);
    return tempList.sort(compare(key, type || 'up'));
  }

  return list;
}

export function numAddByDecimal(...args: number[]) {
  return args.reduce((totalValue, currentValue) => {
    const currentNum = Number(currentValue);
    return Decimal.add(totalValue, isNaN(currentNum) ? 0 : currentNum).toNumber();
  }, 0);
}

export function numMultiplyByDecimal(...args: number[]) {
  return args.reduce((totalValue, currentValue) => {
    const currentNum = Number(currentValue);
    return Decimal.mul(totalValue, isNaN(currentNum) ? 1 : currentNum).toNumber();
  }, 1);
}
