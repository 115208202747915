// @flow
import { SUPPORTED_THEME } from '../../config/global.config.js';
import { TVDebugMode } from '../../utils/envHelper.js';
import { getCurrentPathObj } from '../../utils/urlHelper.js';

const DISABLED_FEATURES = [
  'border_around_the_chart',
  'compare_symbol',
  'display_market_status',
  'header_chart_type',
  'header_compare',
  'header_interval_dialog_button',
  'header_resolutions',
  // 'header_symbol_search',
  'header_widget',,
  'legend_context_menu',
  'remove_library_container_border',
  'show_interval_dialog_on_key_press',
  'symbol_info',
  'symbol_search_hot_key',
  'volume_force_overlay',
];

let ENABLED_FEATURES = [
  'adaptive_logo',
  'dont_show_boolean_study_arguments',
  'hide_last_na_study_output',
  'keep_left_toolbar_visible_on_small_screens',
  'left_toolbar',
  'save_chart_properties_to_local_storage',
  'timeframes_toolbar',
];

// @note 仅保留基本配置
const OVERRIDES = {
  'mainSeriesProperties.areaStyle.linewidth': 1,
  'mainSeriesProperties.areaStyle.priceSource': 'close',
  'mainSeriesProperties.barStyle.barColorsOnPrevClose': false,
  'mainSeriesProperties.barStyle.dontDrawOpen': false,
  'mainSeriesProperties.candleStyle.barColorsOnPrevClose': false,
  'mainSeriesProperties.candleStyle.drawBorder': true,
  'mainSeriesProperties.candleStyle.drawWick': true,
  'mainSeriesProperties.haStyle.barColorsOnPrevClose': false,
  'mainSeriesProperties.haStyle.drawBorder': true,
  'mainSeriesProperties.haStyle.drawWick': true,
  'mainSeriesProperties.hollowCandleStyle.drawBorder': true,
  'mainSeriesProperties.hollowCandleStyle.drawWick': true,
  'mainSeriesProperties.lineStyle.linewidth': 1,
  'mainSeriesProperties.lineStyle.priceSource': 'close',
  'mainSeriesProperties.showCountdown': false,
  'mainSeriesProperties.style': 1,
  'paneProperties.legendProperties.showLegend': false,
  'paneProperties.legendProperties.showSeriesOHLC': true,
  'paneProperties.legendProperties.showSeriesTitle': true,
  'paneProperties.legendProperties.showStudyArguments': true,
  'paneProperties.legendProperties.showStudyTitles': true,
  'paneProperties.legendProperties.showStudyValues': true,
  'volumePaneSize': 'medium',
};

const STUDIES_OVERRIDES = {
  'volume.volume ma.transparency': 0,
  'volume.show ma': false
};

// 单独的颜色配置
export const OVERRIDES_BLACK = {
  'mainSeriesProperties.areaStyle.color1': 'rgba(122, 152, 247, .1)',
  'mainSeriesProperties.areaStyle.color2': 'rgba(122, 152, 247, .02)',
  'mainSeriesProperties.areaStyle.linecolor': '#CCCCCC',
  'mainSeriesProperties.barStyle.downColor': '#EC4551',
  'mainSeriesProperties.barStyle.upColor': '#28C452',
  'mainSeriesProperties.candleStyle.borderColor': '#4e5b85',
  'mainSeriesProperties.candleStyle.borderDownColor': '#EC4551',
  'mainSeriesProperties.candleStyle.borderUpColor': '#28C452',
  'mainSeriesProperties.candleStyle.downColor': '#EC4551',
  'mainSeriesProperties.candleStyle.upColor': '#28C452',
  'mainSeriesProperties.candleStyle.wickDownColor': '#EC4551',
  'mainSeriesProperties.candleStyle.wickUpColor': '#28C452',
  'mainSeriesProperties.haStyle.borderColor': '#4e5b85',
  'mainSeriesProperties.haStyle.borderDownColor': '#EC4551',
  'mainSeriesProperties.haStyle.borderUpColor': '#28C452',
  'mainSeriesProperties.haStyle.downColor': '#EC4551',
  'mainSeriesProperties.haStyle.upColor': '#28C452',
  'mainSeriesProperties.haStyle.wickColor': '#4e5b85',
  'mainSeriesProperties.hollowCandleStyle.borderColor': '#4e5b85',
  'mainSeriesProperties.hollowCandleStyle.borderDownColor': '#EC4551',
  'mainSeriesProperties.hollowCandleStyle.borderUpColor': '#28C452',
  'mainSeriesProperties.hollowCandleStyle.downColor': '#EC4551',
  'mainSeriesProperties.hollowCandleStyle.upColor': '#589065',
  'mainSeriesProperties.lineStyle.color': '#4e5b85',
  'paneProperties.background': '#0F1115',
  'paneProperties.crossHairProperties.color': '#697281',
  'paneProperties.separatorColor': '#17191E',
  'paneProperties.vertGridProperties.color': '#1E1E24',
  'paneProperties.horzGridProperties.color': '#1E1E24',
  'scalesProperties.lineColor': '#232329', // 线条颜色
  'scalesProperties.textColor': '#8B8B8E', // 坐标系上的字体颜色

};

export const OVERRIDES_WHITE = {
  'mainSeriesProperties.areaStyle.color1': 'rgba(122, 152, 247, .1)',
  'mainSeriesProperties.areaStyle.color2': 'rgba(122, 152, 247, .02)',
  'mainSeriesProperties.areaStyle.linecolor': '#CCCCCC', // 分时线颜色
  'mainSeriesProperties.barStyle.downColor': '#EC4551',
  'mainSeriesProperties.barStyle.upColor': '#28C452',
  'mainSeriesProperties.candleStyle.borderColor': '#ffffff',
  'mainSeriesProperties.candleStyle.borderDownColor': '#EC4551',
  'mainSeriesProperties.candleStyle.borderUpColor': '#28C452',
  'mainSeriesProperties.candleStyle.downColor': '#EC4551',
  'mainSeriesProperties.candleStyle.upColor': '#28C452',
  'mainSeriesProperties.candleStyle.wickDownColor': '#EC4551',
  'mainSeriesProperties.candleStyle.wickUpColor': '#28C452',
  'mainSeriesProperties.haStyle.borderColor': '#ffffff',
  'mainSeriesProperties.haStyle.borderDownColor': '#EC4551',
  'mainSeriesProperties.haStyle.borderUpColor': '#28C452',
  'mainSeriesProperties.haStyle.downColor': '#EC4551',
  'mainSeriesProperties.haStyle.upColor': '#28C452',
  'mainSeriesProperties.haStyle.wickColor': '#ffffff',
  'mainSeriesProperties.hollowCandleStyle.borderColor': '#ffffff',
  'mainSeriesProperties.hollowCandleStyle.borderDownColor': '#EC4551',
  'mainSeriesProperties.hollowCandleStyle.borderUpColor': '#28C452',
  'mainSeriesProperties.hollowCandleStyle.downColor': '#EC4551',
  'mainSeriesProperties.hollowCandleStyle.upColor': '#589065',
  'mainSeriesProperties.lineStyle.color': '#ffffff',
  'paneProperties.background': '#ffffff',
  'paneProperties.crossHairProperties.color': '#697281', // 十字定位线颜
  'paneProperties.horzGridProperties.color': '#F9FAFC',
  'paneProperties.legendProperties.showLegend': true,
  'paneProperties.legendProperties.showSeriesOHLC': !0,
  'paneProperties.legendProperties.showSeriesTitle': !0,
  'paneProperties.legendProperties.showStudyArguments': !0,
  'paneProperties.legendProperties.showStudyTitles': !0,
  'paneProperties.legendProperties.showStudyValues': !0,
  'paneProperties.vertGridProperties.color': '#F3F3F3',
  'paneProperties.horzGridProperties.color': '#F3F3F3',
  'scalesProperties.lineColor': '#E8E8E9',
  'scalesProperties.textColor': '#8B8B8E',

};

export function getDefaultChartConfig(
  symbol,
  resolution,
  datafeed,
  libPath,
  locale,
  theme
) {
  const pathObj = getCurrentPathObj();
  // 因图片网址字段只有合约pro接口支持，所以只有合约Pro页面才开启币别图标
  const tmpEnabledFeatures = (pathObj.isSwapNew || pathObj.isProGame) ? [...ENABLED_FEATURES, 'show_symbol_logos'] : [...ENABLED_FEATURES];

  return {
    autosize: true,
    charts_storage_api_version: '1.1',
    client_id: 'weex.com',
    container: 'main_chart',
    custom_css_url: (theme === SUPPORTED_THEME[0] ? 'night.css' : 'white.css') + '?rand=20210706',
    datafeed: datafeed,    // TODO datafeedUrl换成自己的服务端     //	BEWARE: no trailing slash is expected in feed URL
    debug: TVDebugMode, // uncomment this line to see Library errors and warnings in the console
    disabled_features: DISABLED_FEATURES,
    drawings_access: { type: 'black', tools: [{ name: 'Regression Trend' }] },     //	Regression Trend-related functionality is not implemented yet, so it's hidden for a while
    enabled_features: tmpEnabledFeatures,
    fullscreen: false,
    interval: resolution,
    library_path: libPath,
    loading_screen: theme === SUPPORTED_THEME[0] ? { backgroundColor: '#232541', foregroundColor: '#5684F4' } : { backgroundColor: '#ffffff', foregroundColor: '#5684F4' },
    locale: locale,
    overrides: Object.assign( {}, OVERRIDES, theme === SUPPORTED_THEME[0] ? OVERRIDES_BLACK : OVERRIDES_WHITE ),     // 这里面的覆盖设置仍然会被暂存的布局等影响
    studies_overrides: STUDIES_OVERRIDES,
    symbol: symbol,
    timezone: 'Asia/Shanghai',
    autosize: true,
    loading_screen:
      theme === SUPPORTED_THEME[0]
        ? { backgroundColor: '#17171D', foregroundColor: '#5684F4' }
        : { backgroundColor: '#ffffff', foregroundColor: '#5684F4' }
  };
}

export const DEFAULT_BUTTON = [
  {
    slug: 'realtime',
    resolution: '1',
    chartType: 3,
    isMobile: !0
  },
  {
    slug: '1m',
    resolution: '1'
  },
  {
    slug: '5m',
    resolution: '5'
  },
  {
    slug: '15m',
    resolution: '15'
  },
  {
    slug: '30m',
    resolution: '30'
  },
  {
    slug: '1H',
    resolution: '60',
    isMobile: !0
  },
  {
    slug: '4H',
    resolution: '240',
    isMobile: !0
  },
  {
    slug: '12H',
    resolution: '720',
    isMobile: !0
  },
  {
    slug: '1D',
    resolution: '1D',
    isMobile: !0
  },
  {
    slug: '1W',
    resolution: '1W'
  }
];

export const RESOLUTION_TO_SECOND_MAP = {
  '1': '60',
  '5': '300',
  '15': '900',
  '30': '1800',
  '60': '3600',
  '240': '14400',
  '720': '43200',
  '1D': '86400',
  '1W': '604800'
};

export const RESOLUTION_TO_NEW_CONTRACT = {
  '1': 'MINUTE_1',
  '5': 'MINUTE_5',
  '15': 'MINUTE_15',
  '30': 'MINUTE_30',
  '60': 'HOUR_1',
  '120': 'HOUR_2',
  '240': 'HOUR_4',
  '360': 'HOUR_6',
  '720': 'HOUR_12',
  '1D': 'DAY_1',
  '1W': 'WEEK_1',
  '1M': 'MONTH_1'
};

export const CHARTING_LIBRARY = '/charting_library/';
