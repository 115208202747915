import { getWSTimoutMilliSecs, removeTailRecursive } from '~/utils/utils';
import { getTokenDataNew } from '~/utils/urlHelper';
import i18nconf from '~/config/request.i18n.conf.js';
import { getNewContractWSUrl } from '~/utils/urlHelper';
import sEvent from "../../../utils/sensorsEvent";
import queryString from "query-string";
import {inflate} from "pako";
import TokenCookie, {TokenKey} from "~/utils/TokenCookie";

type ListenersMap = {
  error: Map<string, Function>,
  message: Map<string, Function>,
  open: Map<string, Function>,
  close: Map<string, Function>
};

export interface HandleArgs {
  event?: string;
  data: any;
  version?: string;
}

export type ObjectType = { [key: string]: any };
type MsgHandle = (args: HandleArgs) => void;

interface msgHandleMapItem {
  hasSubscribed: boolean;
  handles: MsgHandle[];
}

/**
 * 使用：
 * - 公有推送
 *  1、创建实例：
 *    const WS = new WSServer('wss://x.x.x/realtime_public');
 *  2、订阅：
 *    WS.subscribe('channelName', handler);
 *      + 有对应topicName的消息时，自动调用handler，并传入 Object数据：{ event: string; data: any; timestamp: number };
 *      + 同一个topicName, 可多次订阅
 *  3、取消订阅：
 *    WS.unsubscribe('channelName', handler);
 *      + handler 必须为 订阅时的 函数引用，该功能类似addEventListener/removeEventListener。代码内注意handler的声明、引用
 *      + 当相同主题有多个订阅回调时，仍有订阅未完全取消时，不向后台发送 取消订阅消息，只断开回调
 *  4、实例销毁 (注意多页面共用时谨慎关闭)
 *    WS.close();
 *
 *  *** 自动脉搏通讯，定时发送 'pong' 消息，当断开时自动不断重连、重新订阅。
 *        + ping / pong，当前服务框架不需要关注后端发送的ping，只需要时间间隔内不断发送pong消息即可；
 *
 */

export default class WSServer {
  static instance: WSServer | null = null;
  _pureLink: string = '';
  _isArtificialClosed = false; // 是否是主动关闭
  _msgHandleMap: { [channel: string]: msgHandleMapItem } = {}; // 暂存不同 channel 对应回调
  // eslint-disable-next-line no-undef
  _pulseTimer: NodeJS.Timer | null = null;
  _pulseInterval = 13000;
  _dealMsgListTimer: NodeJS.Timer | null = null;
  _onLine = false;
  _neeReSubscribeMsgList: string[][] = []; // [[channel, armMsg]]
  _reConnectFlag = 0;
  // eslint-disable-next-line no-undef
  _reConnectTimer: NodeJS.Timer | null = null;
  _maxReConnectTimes = 4;
  _directMsgHandler: MsgHandle | null = null;
  version: string;
  _url: UrlProvider;
  _options: Options;
  _binaryType: string; // 与连接的binaryType同步
  _protocols: string | string[];
  _retryCount: number; // 重试次数
  _heartbeats: number; // 记录心跳次数，每当发送一次心跳+1，收到消息时清零
  _closeCalled: boolean; // 标明是手动close
  _shouldReconnect: boolean; // 标明不需要重连
  _connectLock: boolean; // 连接锁
  _connectTimeout: any; // 连接计时器
  _connectTimeoutSec: number; // 连接超时时间
  _connectDelay: number; // 重连延迟时间
  _beatTimeout: any; // 心跳定时器
  _messageQueue: Message[]; // 消息缓存队列
  _listeners: ListenersMap; // 监听器容器
  _channelMap: Map<string, string>; // 保存订阅频道与监听器的映射关系
  productCode: string; // 合约名称
  urlManager: UrlManager; // 连接地址管理
  token: string; // Token
  _messageSent: boolean; // 是否发送过消息
  _isPublic: boolean; // 是否是公有连接
  beatTimer: TimeoutID | null; // ws时延心跳计时器
  heartBeat: number; // 心跳间隔
  timeBoard: { [string]: number }; // 计时板
  _sensorsReportInterval: number;   // 神策上报时间间隔
  _heartbeatTimes: [number];  // 心跳时间间隔记录
  sensorsReport: TimeoutID | null;  // 神策定时器
  _compress: boolean; // 是否开启解压缩

  constructor(link: string, isPublic: boolean, directMsgHandler?: MsgHandle) {
    // console.log('0000 ws isPublic: ', isPublic)
    if (process.client && window.WebSocket) {
      if (link) {
        this._init();
        this._pureLink = `${link}${link.includes('?') ? '&' : '?'}languageType=${i18nconf(this.getLang).lang || 0}`;
        this._directMsgHandler = directMsgHandler || null;
        this._isPublic = isPublic;
        const { search } = new URL(this._pureLink);
        const qs = queryString.parse(search);
        this._compress = qs.compress ? qs.compress === '1' ? '1' : '0' : process.env.WEBSOCKET_COMPRESSION === 'true' ? '1' : '0';
        this._connect();
        this._dealWithException();
        this._observeVisibilityHandler();
      } else {
        console.error('Please input WebSocket URL');
      }
    }
    // else {
    //   console.error('This browser is not support WebSocket, please use other browsers');
    // }
  }

  static async getInstance(isPublic: boolean = true, directMsgHandler?: MsgHandle): WebSocket {
    const url = await getNewContractWSUrl(isPublic);
    if(!this.instance || this.instance?._pureLink !== url){
      this.instance = new WSServer(url, isPublic, directMsgHandler);
    }
    return this.instance;
  }
  /**
   * 订阅topic消息
   * 当有对应topic消息返回是 调用 msgHandle
   * 1）类同 DOM.addEventListener，可以为相同topic 订阅多个回调
   * 2）订阅相同topic时， 当前方案为先取消之前的订阅，再重新订阅，否则 > 1的相同topic订阅 只发送delta包，数据缺少
   * @param channel
   * @param msgHandle
   */
  subscribe = (channel: string, msgHandle: MsgHandle) => {
    if (this._pureLink) {
      // 已经注册该topic
      if (this._msgHandleMap[channel]) {
        this._msgHandleMap[channel].handles.push(msgHandle);
        // 此处判断已经发送过该topic订阅，
        if (this._msgHandleMap[channel].hasSubscribed) {
          this._msgHandleMap[channel].hasSubscribed = false;
          this._armDataAndSend(channel, 'unsubscribe');
        }
      } else {
        this._msgHandleMap[channel] = {
          hasSubscribed: false,
          handles: [msgHandle]
        };
      }
      this._armDataAndSend(channel, 'subscribe');
    }
  };

  // 类同 DOM.removeEventListener
  unsubscribe = (channel: string, msgHandle: MsgHandle) => {
    if (!msgHandle || typeof msgHandle !== 'function') {
      console.error(
        'The unsubscribe works like removeEventListener, need second argument same with subscribe handle'
      );
      return;
    }
    if (channel && this._msgHandleMap[channel]) {
      const handleIdx = this._msgHandleMap[channel]?.handles?.indexOf(msgHandle) || -1;
      if (handleIdx !== -1) {
        this._msgHandleMap[channel].handles.splice(handleIdx, 1);
      }
      if (this._msgHandleMap[channel].handles.length) {
        this._armDataAndSend(channel, 'unsubscribe');
        delete this._msgHandleMap[channel];
      }
    }
  };

  unsubscribeAll = () => {
    if (this.instance) {
      const topics = Object.keys(this._msgHandleMap);
      if (topics.length) {
        topics.forEach(channel => {
          if(channel){
            this._armDataAndSend(channel, 'unsubscribe');
            delete this._msgHandleMap[channel];
          }
        });
      }
    }
  };

  close = () => {
    if (this.instance) {
      const topics = Object.keys(this._msgHandleMap);
      if (topics.length) {
        topics.forEach(channel => {
          this._armDataAndSend(channel, 'unsubscribe');
          delete this._msgHandleMap[channel];
        });
      }

      this._onLine = false;
      this._isArtificialClosed = true;

      this._clearPulse();
      this.instance.close();
      this.instance = null;
      this._rmObserveVisibilityHandler();
    }
  };

  stop = () => {
    if (this.instance) {
      this._isArtificialClosed = true;
      this.instance.close();
      this._clearPulse();
      this.instance = null;
      this._onLine = false;
      this._rmObserveVisibilityHandler();
    }
  };

  reConnect = () => {
    if (!this._onLine) {
      if (this._reConnectFlag >= this._maxReConnectTimes) {
        // 超过最大重连次数
        this._clearPulse();
        this._reConnectFlag = 0;
        this.reConnect();
      } else {
        if (this._reConnectTimer) {
          clearTimeout(this._reConnectTimer);
        }
        this._reConnectTimer = setTimeout(() => {
          if (this.instance) {
            this.instance.close();
            this.instance = null;
          }
          this._setNeeReSendInfo();
          this._connect();
          this._dealWithException();
          this._reConnectFlag += 1;
          this._reConnectTimer = null;
        }, 3 * this._reConnectFlag * 1000);
      }
    }
  };

  /**
   * 增加事件监听
   * @param type
   * @param listener
   * @return {*}
   */
  registerEventListener(type: $Keys<ListenersMap>, listener: Function): string {
    let randomKey = type + String(Date.now());
    this._listeners[type].set(randomKey, listener);
    if (type === 'open' && this.instance && this.readyState === this.instance.OPEN) {
      listener();
    }
    return randomKey;
  }

  /**
   * 由action和params组合生成用于分发的唯一标识
   * @param action
   * @param params
   * @return {string} action|key1:value1|key2:value2
   */
  generateId(action: string, params: any = {}): string {
    const ID_PART_KEYS = ['step', 'productCode'];
    // 如果订阅的是跟单数据 不对params 进行签名
    if (action === 'currentTraceOrders') {
      return action;
    }
    let result = action;
    Object.keys(params)
      .sort((a, b) => {
        return a < b ? -1 : a === b ? 0 : 1;
      })
      .forEach(key => {
        if (ID_PART_KEYS.indexOf(key) > -1) {
          result += `|${key}:${params[key]}`;
        }
      });
    return result;
  }

  /**
   * 返回连接状态，如果没有建立的连接，会根据配置返回一个基本装填
   * 如果startClosed属性为true 表示默认从closed状态开始，否则为正在连接
   * @return {number}
   */
  get readyState(): number {
    if (this.instance) {
      return this.instance.readyState;
    }
    return this._options?.startClosed ? WSServer.CLOSED : WSServer.CONNECTING;
  }

  // 以下内容是为了保证和原生WebSocket拥有相同的标准
  static get CONNECTING() {
    return 0;
  }

  static get OPEN() {
    return 1;
  }

  static get CLOSING() {
    return 2;
  }

  static get CLOSED() {
    return 3;
  }

  get CONNECTING() {
    return WSServer.CONNECTING;
  }

  get OPEN() {
    return WSServer.OPEN;
  }

  get CLOSING() {
    return WSServer.CLOSING;
  }

  get CLOSED() {
    return WSServer.CLOSED;
  }

  _init() {
    this.version = '2.0';
    // binaryType暂不使用
    this._binaryType = 'blob';
    // protocol暂不使用
    this._protocols = TokenCookie.get(TokenKey.CC_TOKEN) || '';
    this._retryCount = -1;
    this._heartbeats = 0;
    this._closeCalled = false;
    this._connectLock = false;
    this._connectTimeout = null;
    this._connectTimeoutSec = 5000;
    this._connectDelay = 0;
    this._messageQueue = [];
    this._shouldReconnect = true;
    this._listeners = {
      open: new Map(),
      close: new Map(),
      error: new Map(),
      message: new Map()
    };
    this._channelMap = new Map();
    this._messageSent = false;
    this.heartBeat = 5 * 1000; // 心跳间隔5s
    this.timeBoard = {}; // 初始化计时板
  this._sensorsReportInterval = 60 * 1000; // 神策上报时间间隔60s
  this._heartbeatTimes = []; // 初始化心跳时延间隔记录
  }

  _connect() {
    if (!this._onLine && this._pureLink) {
      const link = this._pureLink;
      this._isArtificialClosed = false;
      // 获取token等头信息
      const token = getTokenDataNew(link);
      // subProtocol尾端不能带有 = 号
      this._protocols = removeTailRecursive(token, '=');
      // 建立socket连接
      this.instance =new WebSocket(link, this._protocols)
      // 分发消息
      this._dealWithMsg();
    }
  }

  /**
   * 封装【待重新发送到服务端信息】列表
   */
  _setNeeReSendInfo() {
    this._neeReSubscribeMsgList.length = 0;
    const topicList = Object.keys(this._msgHandleMap);
    if (topicList.length) {
      topicList.forEach(channel => {
        this._msgHandleMap[channel].hasSubscribed = false;
        const msg = {
          event: 'subscribe',
          channel
        };
        this._neeReSubscribeMsgList.push([channel, JSON.stringify(msg)]);
      });
    }
  }


/**
 * 解压缩信息
 * @param rawMessage
 * @private
 */
_inflateMessage(rawMessage: any) {
  if (rawMessage instanceof Blob) {  // 处理后端返回压缩数据
    let reader = new FileReader();
    let context = this;

    reader.addEventListener('loadend', function() {
      // $flow-disable-line
      let decodeMessage = JSON.parse(String(inflate(reader.result, { to: 'string' })));
      context._makePulse(decodeMessage);
      if (context._directMsgHandler && decodeMessage.msg) {
        const { event, data, type, version } = decodeMessage.msg;
        context._directMsgHandler({ event, data, type, version });
      } else {
        context._triggerHandle(decodeMessage);
      }
      reader = null
    });

    reader.onerror = function(error) {
      console.log('解压出现问题', error);
      // window.location.reload();
    };

    reader.readAsArrayBuffer(rawMessage);
  } else {  // 兼容前端开启压缩时候后端没有压缩数据
    const resObj = JSON.parse(rawMessage || '{}');
    this._makePulse(resObj);
    if (this._directMsgHandler && resObj.msg) {
      const { event, data, type, version } = resObj.msg;
      this._directMsgHandler({ event, data, type, version });
    } else {
      this._triggerHandle(resObj);
    }
  }
}

  /**
   * 消息分类处理
   */
  _dealWithMsg() {
    if (this.instance) {
      this.instance.onmessage = (events: MessageEvent) => {
        try {
          if(this._compress === '1' || events.data instanceof Blob){  // 兼容前端没有开启压缩时候后端返回压缩数据
            const resObj = events.data;
            this._inflateMessage(resObj)  // 如果开启压缩或者后端返回压缩数据则解压缩
          }else {
            const resObj = JSON.parse(events.data || '{}');
            this._makePulse(resObj);
            if (this._directMsgHandler && resObj.msg) {
              const { event, data, type, version } = resObj.msg;
              this._directMsgHandler({ event, data, type, version });
            } else {
              this._triggerHandle(resObj);
            }
          }
        } catch (e) {
          console.log(e, events);
        }
      };
    }
  }

  /**
   * 服务端返回消息，触发对应topic缓存的【回调列表】
   * @param results
   */
  _triggerHandle(results: { [key: string]: any }) {
    const { channel, event, type, data } = results;
    // 过滤 订阅后 回传的 与数据无关的 event
    if (!['unsubscribe'].includes(event)) {
      const msgHandles = this._msgHandleMap[channel] && this._msgHandleMap[channel].handles;
      if (msgHandles && msgHandles.length) {
        msgHandles.forEach(handle => {
          handle({
            event,
            type,
            data
          });
        });
      }
    }
  }

  /**
   * 向服务端发送消息
   * 1、当前仅需支持订阅 - subscribe 和 取消订阅 - unsubscribe
   * 2、当发送订阅时，如果和服务端并未建立连接，则缓存该订阅消息
   * @param channel
   * @param event
   */
  _armDataAndSend(channel: string, event: 'subscribe' | 'unsubscribe') {
    const msg = {
      event,
      channel
    };
    if (this._onLine && this.instance) {
      // 神策上报订阅信息
      sEvent('ws_swapnew_content', this._pureLink, {ext: JSON.stringify(msg)})
      event === 'subscribe'
        ? this._sendToService(channel, JSON.stringify(msg))
        : this.send(JSON.stringify(msg));
    } else if (event === 'subscribe') {
      this._neeReSubscribeMsgList.push([channel, JSON.stringify(msg)]);
      this._dealWithException();
    }
  }

  _sendToService(channel: string, armMsg: string) {
    if (this._msgHandleMap[channel]) {
      if (!this._msgHandleMap[channel].hasSubscribed && this.instance) {
        this.send(armMsg);
      }
      this._msgHandleMap[channel].hasSubscribed = true;
    }
  }

  /**
   * 收到服务端ping消息时，发送脉搏消息pong
   */
  _makePulse(data) {
    if (data) {
      // private 使用
      if (data.type && data.type == 'ping') {
        this.send(JSON.stringify({ type: 'pong', time: Date.now() + '' }));
      }
      // private 使用
      if (data.type && data.type == 'pong') {
        // 记录心跳用时
        let time = this._timeEnd('ping');
        this._heartbeatTimes.push(time);
      }
      // public 使用
      if (data.event && data.event == 'ping') {
        this.send(JSON.stringify({ event: 'pong', time: Date.now() + '' }));
      }
      // public 使用，ws时延检测心跳
      if (data.event && data.event == 'pong') {
        // 记录心跳用时
        let time = this._timeEnd('ping');
        // 记录最新测速结果到store
        window.$nuxt.$store.commit('newTrading/SET_WS_SPEED', time)
        this._heartbeatTimes.push(time);
      }
      if(!this._isPublic){
        // 如果是私有订阅，获取推送数据并分开上报
        if(data.type !== 'ping' && data.type !== 'pong' && data.type !== 'connected'){
          let event = data.msg && data.msg.event;
          let dataObj = data.msg && data.msg.data;
          if(dataObj && dataObj.account && dataObj.account instanceof Array){
            let msg = {
              name: event,
              account: dataObj.account.map(e => ({
                clientAccountId: e.clientAccountId,
                status: e.status
              }))
            }
            sEvent('ws_swapnew_private_content', this._pureLink, {ext: JSON.stringify(msg)})
          }
          if(dataObj && dataObj.collateral && dataObj.collateral instanceof Array){
            let msg = {
              name: event,
              collateral: dataObj.collateral.map(e => ({
                amount: e.amount,
                marginMode: e.marginMode,
                crossContractId: e.crossContractId,
                isolatedPositionId: e.isolatedPositionId,
              }))
            }
            sEvent('ws_swapnew_private_content', this._pureLink, {ext: JSON.stringify(msg)})
          }
          if(dataObj && dataObj.order && dataObj.order instanceof Array){
            let msg = {
              name: event,
              order: dataObj.order.map(e => ({
                marginMode: e.marginMode,
                orderSide: e.orderSide,
                positionSide: e.positionSide,
                size:e.size,
                type: e.type,
                triggerPrice: e.triggerPrice,
                triggerPriceType: e.triggerPriceType,
              }))
            }
            sEvent('ws_swapnew_private_content', this._pureLink, {ext: JSON.stringify(msg)})
          }
          if(dataObj && dataObj.position && dataObj.position instanceof Array){
            let msg = {
              name: event,
              position: dataObj.position.map(e => ({
                contractId: e.contractId,
                marginMode: e.marginMode,
                separatedMode: e.separatedMode,
                side: e.side,
                size: e.size,
              }))
            }
            sEvent('ws_swapnew_private_content', this._pureLink, {ext: JSON.stringify(msg)})
          }

        }
      }
    }
    // this.clearPulse();
    // this._pulseTimer = setInterval(() => {
    //   if (this._onLine && this.instance) {
    //     const info = {
    //       event: 'pong',
    //       args: Date.now(),
    //     };
    //     this.instance.send(JSON.stringify(info));
    //   } else {
    //     this.clearPulse();
    //   }
    // }, this._pulseInterval);
  }

  _clearPulse() {
    if (this._pulseTimer) {
      clearInterval(this._pulseTimer);
      this._pulseTimer = null;
    }
  }

  _clearMsgListTimer() {
    if (this._dealMsgListTimer) {
      clearTimeout(this._dealMsgListTimer);
      this._dealMsgListTimer = null;
    }
  }

  _clearSensorsReportTimer() {
    this.sensorsReport && clearInterval(this.sensorsReport);
    if(this._heartbeatTimes.length > 0){
      sEvent('ws_response_time', this._pureLink, {ext: this._heartbeatTimes.join(',')})
      this._heartbeatTimes = [];
    }
  }

  /**
   * 异常处理
   * 1、连接建立时，
   *    1）公有推送启动脉搏消息
   *    2）发送待订阅列表（主动订阅时，存在调用时推送并没有建立连接，这时会缓存入待订阅列表，此处发送该列表消息）
   * 2、连接出错、或被动中断重置全局连接状态相关的变量
   * 3、页面卸载，中断关闭连接
   */
  _dealWithException() {
    if (this.instance) {
      this.instance.onopen = () => {
        this._reConnectFlag = 0;
        this._onLine = true;

        // 开启心跳
        this._heartbeat()

        this._dealMsgListTimer = setTimeout(() => {
          if (this._neeReSubscribeMsgList.length) {
            this._neeReSubscribeMsgList.forEach(msg => {
              this._sendToService(msg[0], msg[1]);
            });
            this._neeReSubscribeMsgList.length = 0;
          }
        }, 500);
      };

      this.instance.onerror = () => {
        console.warn('ws error... ', this._pureLink);
        this._closedHandler();
      };

      this.instance.onclose = (ev: CloseEvent) => {
        console.warn('ws close ... ', ev.code, ev.reason || 'null', this._pureLink);
        this._closedHandler();
      };

      window.addEventListener('beforeunload', () => {
        this._clearPulse();
        this._clearMsgListTimer();
        this._clearSensorsReportTimer();  //页面关闭时清除神策定时器
        this._rmObserveVisibilityHandler();
        if (this.instance) {
          this.instance.close();
        }
      });
    } else {
      // 不是手动关闭才需要再次重连，避免快速切换线路导致的关闭后依然重连异常
      if(!this._isArtificialClosed) this.reConnect();
    }
  }

  _closedHandler = () => {
    this._onLine = false;
    if (this.instance) {
      this.instance.onmessage = null;
      this.instance.close();
    }
    this.instance = null;
    if (!this._isArtificialClosed) {
      this.reConnect();
    }
  };

  _observeVisibilityHandler = () => {
    document.addEventListener('visibilitychange', this._handleVisibilityChanged);
  };

  _rmObserveVisibilityHandler = () => {
    document.removeEventListener('visibilitychange', this._handleVisibilityChanged);
  };

  /**
   * 后台化转入 判断是否重连
   */
  _handleVisibilityChanged = () => {
    if (!document.hidden && !this._onLine) {
      this._reConnectFlag = 0;
      this.reConnect();
    }
  };

  // 前端心跳，用于ws时延检测
  _heartbeat() {
    this.sensorsReport && clearInterval(this.sensorsReport);
    let beat = () => {
        if (this.beatTimer) {
            clearTimeout(this.beatTimer);
        }
        if (this.instance && this.readyState === this.instance.OPEN) {
            if(this._isPublic){
              this.send(JSON.stringify({ event: 'ping', time: Date.now() + '' }));
            }else {
              this.send(JSON.stringify({ type: 'ping', time: Date.now() + '' }));
            }
            this._time('ping'); // 记录ping开始时间
            // 30s发送一次心跳
            this.beatTimer = setTimeout(() => {
                beat();
            }, this.heartBeat);
        }else {
          // 链接异常则停止定时器并上报数据
          this._clearSensorsReportTimer();
        }
    };

    this.sensorsReport = setInterval(() => {
      if(this._heartbeatTimes.length > 0){
        sEvent('ws_response_time', this._pureLink, {ext: this._heartbeatTimes.join(',')})
        this._heartbeatTimes = [];
      }
    } , this._sensorsReportInterval)
    beat();
  }


  /**
   * 计时
   * @param label
   * @private
   */
  _time(label: string): void {
    this.timeBoard[label] = Date.now();
  }

  /**
   * 计时结束
   * @param label
   * @private
   */
  _timeEnd(label: string): number {
    if (this.timeBoard[label]) {
      return Date.now() - this.timeBoard[label];
    }

    return 0;
  }

  /**
   * 抽离send方法，判断ws实例存在并且是打开状态
   * @param msg
   */
  send(msg) {
    if(this.instance && this.readyState === this.OPEN){
      this.instance.send(msg)
    }
  }
}
