
import { HandleArgs, ObjectType } from './lib/core';
import { isClient } from '~/utils/envHelper';

let tickerAllDataMap: ObjectType = {};

/* 订阅 tickerAll  */
export const subscribeTickerAll = () => {
  isClient && window?.$nuxt?.$publicWS.getInstance().then(socket=>{
    socket.subscribe('ticker.all', _handleTickerAll);
  });
}
export const unSubscribeTickerAll = () => {
  isClient && window?.$nuxt?.$publicWS.getInstance().then(socket=>{
    socket.unsubscribe('ticker.all', _handleTickerAll);
  });
}

function _handleTickerAll({ event, data }: HandleArgs) {
  // 首次订阅回传数据
  if (event === 'subscribed') {
    tickerAllDataMap = {};
  }
  if (event === 'payload') {
    if (data?.length > 0) {
      data.forEach((symbolInfo) => {
        const lastInfo = tickerAllDataMap[symbolInfo.contractId];
        if (lastInfo) {
          tickerAllDataMap[symbolInfo.contractId] = {
            ...lastInfo,
            ...symbolInfo
          };
        } else {
          tickerAllDataMap[symbolInfo.contractId] = symbolInfo;
        }
      });
      if(isClient){
        window.$nuxt.$store?.dispatch('newTrading/UPDATE_NEW_ALL_TICKER', tickerAllDataMap);
        // 为保持数据一致性，更新当前合约的 ticker 数据，不再单独订阅ticker
        const currentContractId = window.$nuxt.$store?.state?.newTrading?.currentContractId;
        if(currentContractId){
          window.$nuxt.$store?.dispatch('newTrading/UPDATE_NEW_TICKER', tickerAllDataMap[currentContractId]);
        }
      }
    }
  }
}
